import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function Wamo() {
  return <Seo title={sections.Wamo.title} />;
}

export default Wamo;
